.popup-container {
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shadow-container {
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.input-style {
  border: none;
  width: 100%;
  text-align: center;
}
