.popup-container {
  background: rgba(255, 255, 255, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
