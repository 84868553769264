.term-and-privacy-container {
  height: 100%;
  overflow: auto;
  /* border: 1px solid var(--iq-border); */
}

.term-and-privacy-without-number {
  list-style-type: none;
  list-style-position: outside;
}

.term-and-privacy-list {
  list-style-type: square;
}

.term-and-privacy-list li {
  list-style-type: square;
}

.term-and-privacy-without-mark {
    margin: 0px;
    padding: 0px;
}
