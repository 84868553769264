/*------------- Variable ------------*/

:root {
  --iq-title-text: #11141b;
  --iq-body-text: #777D74;
  --iq-body-bg : #fbfcff;
  --iq-primary: #0dd6b8;
  --iq-secondary: #a09e9e;
  --iq-success: #49f0d3;
  --iq-warning: #ffba68;
  --iq-danger: #ff9b8a;
  --iq-info: #d592ff;
  --iq-dark: #3f414d;
  --iq-light: #fafafb;
  --iq-white: #ffffff;
  --iq-black: #000000;
  --iq-gray: #b1b1b1;
  --iq-light-primary: #fbfcff;
  --iq-light-secondary: #f1f2f1;
  --iq-light-success: #eefff2;
  --iq-light-danger: #fff5f4;
  --iq-light-warning: #fffcf3;
  --iq-light-info: #eee6f5;
  --iq-light-dark: #f0efef;
  --iq-primary-hover: #11e1c2;
   --iq-secondary-hover: #807e7e;
  --iq-success-hover: #42f9d9;
  --iq-danger-hover: #f78673;
  --iq-warning-hover: #fdb45e;
  --iq-info-hover: #c280ea;
  --iq-dark-hover: #44454e;
  --iq-light-hover: #eaeeff;
  --iq-border: #ededf0;
  --iq-border-light: #f1f1f1;
  --iq-border-danger: #ff9273;
  --iq-light-card: #ffffff;
  --iq-primary-rgb: 100, 114, 248;
  --iq-primary-light: #f3fffe;
  /* color for customizer*/
  --iq-color1: #4e37b2;
  --iq-color2: #02d871;
  --iq-color3: #309cf3;
  --iq-color4: #ff00ff;
  --iq-color5: #fb1f4c;
  --iq-color6: #00ced1;
  --iq-color7: #f35f3b;
  --iq-color8: #fde117;
  --iq-color9: #ff0000;
  --iq-color10: #88028c;
  --iq-color11: #00d5ab;
  --iq-color12: #ce9252;
  --iq-color13: #f9ae02;
  --iq-color14: #1e7fe4;
  --iq-color15: #006400;
  --iq-color16: #fa7c04;
  --iq-color17: #81bf02;
  --iq-color18: #99a7ca;
  --iq-color19: #3d2a26;
  --iq-color20: #a0746b;
}
